import React, { useState } from 'react';

import css from './ListingImageGalleryNew.module.css';
import Slider from 'react-slick';

import InnerImageZoom from 'react-inner-image-zoom';
import { Button, IconCollection, Modal, ResponsiveImage } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import { updateProfile } from '../../ProfileSettingsPage/ProfileSettingsPage.duck';
import { useDispatch, useSelector } from 'react-redux';



const ListingImageGalleryNew = props => {
  const {
    intl,
    rootClassName,
    className,
    images,
    imageVariants,
    thumbnailVariants,
    variants,
    isPreviewPage,
    listingImagesUrl,
    currentUser,
    currentListing,
    isOwnListing,
    heroImageId
  } = props;

  const [activeImg, setActiveImg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDislikeOpen, setIsDislikeOpen] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector(state => state);
  const { updateInProgress } = state.ProfileSettingsPage;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) { },
    afterChange: function (currentSlide) { },
  };

  const generateImgProps2 = () => {
    let src = '';
    let zoomSrc = '';

    if (activeImg) {
      src = activeImg;
      zoomSrc = activeImg;
    } else if (listingImagesUrl?.length > 0 && listingImagesUrl[0]?.url) {
      src = listingImagesUrl[0].url;
      zoomSrc = listingImagesUrl[0].url;
    }

    return {
      src,
      zoomSrc,
      zoomType: 'hover',
      zoomPreload: true,
      zoomScale: 4.0,
      // hasSpacer: true,
    };
  };

  const imgProps2 = generateImgProps2();

  const generateImgProps = () => {
    return {
      src: activeImg ? activeImg : images[0]?.attributes?.variants['scaled-large']?.url,
      //   zoomSrc: activeImg ? activeImg.variants["scaled-medium"].url :images[0].attributes.variants["scaled-medium"].url,
      //   zoomType: "hover",
      //   zoomPreload: true,
      hasSpacer: true,
      zoomType: 'hover',
    };
  };

  const imgProps1 = generateImgProps();
  const id = currentListing.id.uuid;
  const { likeItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};
  const likeIndex = likeItems && likeItems.findIndex(e => e == id);

  const handleLikeItems = async () => {
    if (isOwnListing) {
      return null;
    }
    if (!currentUser?.id) {
      setIsModalOpen(true);
    } else {
      if (likeIndex > -1) {
        likeItems.splice(likeIndex, 1);
      } else {
        likeItems.push(id);
      }
      const profile = {
        protectedData: {
          likeItems,
        },
      };
      const data = !isOwnListing && (await dispatch(updateProfile(profile)));
      data && setIsDislikeOpen(false);
    }
  };

  const dislikeModal = (
    <Modal
      id="loginFormRequriedModal"
      isOpen={isDislikeOpen}
      onClose={e => {
        e.preventDefault();
        setIsDislikeOpen(false);
      }}
      isLandingPage={false}
      usePortal
      onManageDisableScrolling={() => { }}
      className={css.modalRemove}
    >
      <div className={css.modalContent}>
        <svg
          className={css.deleteIcon}
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z"></path>
          </g>
        </svg>
        <h3 className={css.modalTitle}>
          <FormattedMessage id="ListingCard.heading" />
        </h3>
        <p className={css.modalText}>
          <FormattedMessage id="ListingCard.dislikeText" />
        </p>
        <div className={css.buttonGroups}>
          <Button className={css.cancelBtn} onClick={() => setIsDislikeOpen(false)}>
            <FormattedMessage id="ListingCard.cancel" />
          </Button>
          <Button
            className={css.removeBtn}
            onClick={() => {
              handleLikeItems();
            }}
            inProgress={updateInProgress}
          >
            <FormattedMessage id="ListingCard.remove" />
          </Button>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className={css.productGallery} data-testid="carousel">
      {listingImagesUrl && listingImagesUrl.length ? (
        <>
          <div className={css.leftSideNav}>
            <Slider {...settings}>
              {listingImagesUrl?.map((item, ind) => (
                <div onClick={() => setActiveImg(item?.url)}>
                  {/* <ResponsiveImage
                    renderCloudinaryImage={{
                      secure_url: item.url,
                      alt: `Listing Image ${ind + 1}`,
                      variantsToRender: [
                        'listing-card-4x',
                        'listing-card-2x',
                        'listing-card',
                      ],
                      defaultVariant: 'listing-card-4x',
                    }}
                  /> */}
                  <img src={item.url} alt='Listing image' width="100%" height="100%" />
                </div>

              ))}
            </Slider>
          </div>
          <div className={css.mainImg}>
            <div
              onClick={e => {
                e.preventDefault();
                likeIndex > -1 ? setIsDislikeOpen(true) : handleLikeItems();
              }}
              className={css.likeIcon}
            >
              {likeIndex > -1 ? (
                <span>
                  <IconCollection icon="dislikeIcon" />
                </span>
              ) : (
                <span>
                  <IconCollection icon="likeIcon" />
                </span>
              )}
            </div>
            {dislikeModal}
            <a
              href={activeImg ? activeImg : listingImagesUrl[0]?.url}
              target="_blank" rel="noopener noreferrer"
            >
              <img
                className={css.galleryImage}
                key={activeImg ? activeImg : listingImagesUrl[0]?.url}
                src={activeImg ? activeImg : listingImagesUrl[0]?.url}
                alt="Listing Image"
              />
              {/* <ResponsiveImage
                className={css.galleryImage}
                renderCloudinaryImage={{
                  secure_url: activeImg ? activeImg : listingImagesUrl[0]?.url,
                  alt: `Listing Image`,
                  variantsToRender: imageVariants,
                  defaultVariant: imageVariants[0],
                }}
              /> */}
            </a>
          </div>
        </>
      ) : (
        <>
          <div className={css.leftSideNav}>
            <Slider {...settings}>
              {images?.map(item => (
                <img
                  key={item?.id?.uuid}
                  onClick={() => setActiveImg(item?.attributes?.variants['scaled-large']?.url)}
                  src={item?.attributes?.variants['scaled-large']?.url}
                />
              ))}
            </Slider>
          </div>
          <div className={css.mainImg}>
            <div
              onClick={e => {
                e.preventDefault();
                likeIndex > -1 ? setIsDislikeOpen(true) : handleLikeItems();
              }}
              className={css.likeIcon}
            >
              {likeIndex > -1 ? (
                <span>
                  <IconCollection icon="dislikeIcon" />
                </span>
              ) : (
                <span>
                  <IconCollection icon="likeIcon" />
                </span>
              )}
            </div>
            {dislikeModal}
            <a 
            href={activeImg ? activeImg : images[0]?.attributes?.variants['scaled-large']?.url} 
            target="_blank" rel="noopener noreferrer" >
              <img  className={css.galleryImage} key={activeImg ? activeImg : images[0]?.attributes?.variants['scaled-large']?.url} src={activeImg ? activeImg : images[0]?.attributes?.variants['scaled-large']?.url} alt="Listing Image" />

            </a>
            {/* <InnerImageZoom {...imgProps1} /> */}
            {/* <img src={activeImg ? activeImg :images[0].attributes.variants["scaled-large"].url} alt='' /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ListingImageGalleryNew;
