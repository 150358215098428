import React from 'react';
// import ListingImageGallery from './ListingImageGallery/ListingImageGallery';
import ListingImageGalleryNew from './ListingImageGalleryNew/ListingImageGalleryNew';
import css from './ListingPage.module.css';

function moveToFirst(arr, index) {
  if (index > -1 && index < arr.length) {
    const element = arr.splice(index, 1)[0]; // Remove the element at the given index
    arr.unshift(element); // Add the element to the beginning of the array
  }
  return arr;
};

const SectionGallery = props => {
  const { listing, variantPrefix ,intl,variants,isPreviewPage,currentUser,isOwnListing} = props;
  const images = listing.images;
  const { listingImagesUrl, heroImageId } = listing?.attributes?.publicData || {};
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const heroImgIndex = listingImagesUrl?.findIndex(img => img.public_id === heroImageId);
  let updatedUrlsOrder = listingImagesUrl;
  if(heroImgIndex !== -1){
    updatedUrlsOrder = moveToFirst(listingImagesUrl, heroImgIndex)
  };

  return (
    <div className={css.productGallery} data-testid="carousel">
      {/* <ListingImageGallery
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        variants={variants}
        isPreviewPage={isPreviewPage}
        listingImagesUrl={listingImagesUrl}
      /> */}
      <ListingImageGalleryNew
        images={images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        variants={variants}
        isPreviewPage={isPreviewPage}
        listingImagesUrl={updatedUrlsOrder}
        currentUser = {currentUser}
        currentListing = {listing}
        isOwnListing={isOwnListing}
        intl={intl}
      />
    </div>
  );
};

export default SectionGallery;
